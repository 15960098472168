# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

# file upload button
init_file_upload_btn = (element) ->
  element = document unless element
  if $(element).find('.file-upload-container').length > 0
    $(element).find('.file-upload-container input[type="file"]').off().on('change', (event) ->
      file_input = $(event.target)
      input_container = file_input.closest('.file-upload-container')
      selected_file_label = input_container.find('.selected-file-label')
      if file_input.val()
        selected_file_label.text(file_input.val().split('\\').pop())
      else
        selected_file_label.text(selected_file_label.data('label'))
    )

$ ->
  init_file_upload_btn()

$(document).on 'turbolinks:load', ->
  init_file_upload_btn()

$(document).on 'cocoon:after-insert', (e,item)->
  # after inserting a new element via cocoon, we need a unique id for the file upload input and label
  if $(item).find('.file-upload-container').length > 0
    $(item).find('.file-upload-container input[type="file"]').each((index, element) =>
      old_id = $(element).attr('id')
      new_id = old_id + new Date().getTime()
      $(element).closest('.file-upload-container').find("label[for=\"#{old_id}\"]").attr('for', new_id)
      $(element).attr('id', new_id)
    )

  # init file upload button within new inserted cocoon element
  init_file_upload_btn(item)

