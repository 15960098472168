# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

# file upload button
switch_menu = (date) ->
  delivery = $('#order_with_delivery')
  if date
    day = date.substring(0, 10)
    time = date.substring(11,16)
    if time < '11:30' || time > '14:30'
      day = 'noST'
    if time >= "12:00" && time <= "14:00"
      delivery.prop('disabled', false)
    else
      delivery.prop('checked', false)
      delivery.prop('disabled', true)
  else
    day = new Date(0)

  container = $(".menu-container[data-day='#{day}']")
  container.find('.formrow-bgrd').removeClass('readonly')
  container.find('input').removeAttr('disabled')

  # clear amounts of not selected days
  containers = $(".menu-container.fast_menu")
  for container in containers
    do (container) ->
      contain = $(container)
      c_day = contain.data('day')
      if c_day != day
        # disable all inputs
        contain.find('.amount').val(0)
        contain.find('.formrow-bgrd').addClass('readonly')
        contain.find('input').attr('disabled', 'disabled')
  return

switch_delivery = () ->
  if $('#order_shipping_differs').prop('checked')
    $('.cell.delivery').show()
  else
    $('.cell.delivery').hide()

init_datetime_lunch = () ->
  $('#datetime-lunch').on 'change', (event) ->
    switch_menu($(this).val())
  switch_menu($('#datetime-lunch').val())
  $('#order_shipping_differs').on 'change', switch_delivery
  switch_delivery()
  return

$ ->
  init_datetime_lunch()

$(document).on 'turbolinks:load', ->
  init_datetime_lunch()
