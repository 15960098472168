# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
show_tree = ()->
  page_tree=$('#page_tree')
  return if page_tree.length == 0 || page_tree.data('initialized')

  page_tree.data('initialized', true)
  page_url=page_tree.data('page-url')
  nspace=' &nbsp; '
  page_tree.tree
    dragAndDrop: true
    autoEscape: false
    saveState: true
    onCreateLi: (node,$li)->
      # Dekoration des Seitentitel mit Ländermarken und Bearbeitungs-Links
      id=node.id
      url=page_url+'/'+id
      edit_link='<a href="'+url+'/edit" >Bearbeiten</a>'
      view_link='<a href="' + node.url + '" target="_blank">Anzeigen</a>'
      if node['deletable']
        del_link = nspace + '<a href="'+url+'" data-confirm="Sind Sie sicher, dass Sie die Page &laquo;'+node.title+'&raquo; löschen möchten?" data-method="delete" rel="nofollow" title="Diese Seite dauerhaft entfernen">Löschen</a>'
      else
        del_link = ''

      links = del_link + nspace + edit_link + nspace + view_link
      links= '<span class="actions right">'+links+'</span>'
      $li.find('.jqtree-title').after(links)

      true
    onCanSelectNode: (node)->
      # node cannot be unselected
      sel = $('#page_tree').tree('isNodeSelected',node)
      !sel

  # Verschieben der Seiten in der DB nachvollziehen
  page_tree.bind 'tree.move', (e)->
    info=e.move_info
    $.post page_url+'/move',{
      moved: info.moved_node.id
      target: info.target_node.id
      position: info.position
    }


# show / hide variant parts of pages part depending on type
show_variants= (that)->
  # hide all divs
  that.find('.variants').hide()
  kind=that.find('.page_page_parts_kind select').val()
  if kind>''
    that.find(".#{kind}").show()
  that.find('.select2').select2()

init_pages = ->
  show_tree()
  $(document).on 'change', '#new_edit_locale', ->
    $(this).closest('form').submit()

  # make page parts etc sortable
  $('.sortable').sortable
    axis: 'y'
    handle: '.handle'
    update: ->
      $.post($(this).data('update-url'), $(this).sortable('serialize'))

  $('#page-parts').on 'cocoon:after-insert', (e,item)->
    part=$(item)
    part.find('.variants').hide()
    document.dispatchEvent(new Event('init_ckeditor'))
    document.dispatchEvent(new Event('init_toggle_hidden'))
  $('#page-parts .nested-fields').each (idx,item)->
    show_variants($(this))
  $('#page-parts').on 'change', '.page_page_parts_kind select', ->
    that=$(this)
    show_variants(that.closest('.nested-fields'))


$ ->
  init_pages()

$(document).on 'turbolinks:load', ->
  init_pages()