// initialize the page
$(document).on('turbolinks:load', function () {
    initApp();
});
$(document).ready(function () {
    initApp();
});

function initApp() {
    if ($('body').data('initialized')) {
        return;
    }
    $('body').data('initialized', true);
    var btn_menu = $('.btn_menu');
    $(btn_menu).on('click', function (e) {
        e.preventDefault();
        $(btn_menu).toggleClass('is_active');
        $(".menu-main").slideToggle();
        $("#header").toggleClass("menu-main-toggle");
        //mainMenu();
    });


    // Sticky
    var header = document.getElementById("header");
    if (!header) {
        return;
    }
    var sticky = header.offsetTop;
    var stickybg = 700;

    if ($(window).width() < 1025) {
        stickybg = 400;
    }

    // When the user scrolls the page, execute myFunction
    window.onscroll = function () {
        myFunction()
    };

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
        if (window.pageYOffset > sticky) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
        if (window.pageYOffset > stickybg) {
            header.classList.add("stickybg");
        } else {
            header.classList.remove("stickybg");
        }
    }

    /* slider */
    $('.slider-2tile').slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        //slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [{
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });
    $('.slider-3tile').slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [{
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.slider-5tile').slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [{
            breakpoint: 769,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

    /* lightbox */
    $(".lightbox").nivoLightbox();

    /* datepicker */
    const time_to_prepare = 35;
    const time_logic = function (currentDateTime) {
        const today = new Date();
        if (currentDateTime.getDate() === today.getDate() &&
            currentDateTime.getMonth() === today.getMonth() &&
            currentDateTime.getFullYear() === today.getFullYear()
        ) {
            // Today
            let soon = new Date();
            soon.setMinutes(soon.getMinutes() + time_to_prepare);
            this.setOptions({
                minTime: soon
            })
        }
        else {
            this.setOptions({minTime: '11:30'})
        }
    }
    if ($('#datetime-lunch').length) {
        $('#datetime-lunch').datetimepicker({
            format: 'd.m.Y H:i',
            lang: 'de',
            step: 15,
            dayOfWeekStart: 1,
            disabledWeekDays: [0, 6],
            minDate: new Date(),
            minTime: '11:30',
            maxTime: '17:01',
            onChangeDateTime: time_logic,
            onShow: time_logic
        });
    }

    /* select */
    function selectBoxChange(elm) {
        let clazz = $(elm).children(':selected').val();
        if (clazz === '') {
            clazz = 'empty';
        }
        $(elm).attr('class', '').addClass(clazz);
    }
    $('select').each(function () {
        selectBoxChange(this)
    }).on('change', function (ev) {
        selectBoxChange(this)
    });

    /* qnt button */
    $(".qnt-button").on("click", function () {
        var button = $(this);
        if (button.closest(".formrow-bgrd.readonly").length > 0) {
            return;
        }
        var oldValue = button.closest(".qnt-box").find("input").val();
        //alert(button.text());
        if (button.text() == "+") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 0) {
                newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        if (newVal > 0) {
            button.closest(".formrow-bgrd").addClass("borderbox-yellow");
        } else {
            button.closest(".formrow-bgrd").removeClass("borderbox-yellow");
        }
        button.closest(".qnt-box").find("input").val(newVal);
    });

    /* to top */
    $("#back-top").hide();
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#back-top').fadeIn();
            } else {
                $('#back-top').fadeOut();
            }
        });

        // scroll body to 0px on click
        $('#back-top a').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });

    function isOnScreen(elem) {
        // if the element doesn't exist, abort
        if (elem.length == 0) {
            return;
        }
        var $window = jQuery(window)
        var viewport_top = $window.scrollTop()
        var viewport_height = $window.height()
        var viewport_bottom = viewport_top + viewport_height
        var $elem = jQuery(elem)
        var top = $elem.offset().top
        var height = $elem.height()
        var bottom = top + height

        return (top >= viewport_top && top < viewport_bottom)
            || (bottom > viewport_top && bottom <= viewport_bottom)
            || (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    }
    if ($(window).width() > 640) {
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            $(".orbit-slide .background").css({
                width: 100 + scroll / 80 + "%",
                backgroundPositionX: 20 + scroll / 10 + "%"
            });

            /* move while scrolling */

            $(".move-lr").each(function (index) {
                var elementOffset = $(this).offset().top - $(window).scrollTop();
                var movelr = parseInt($(this).attr("data-move-start"));
                if (!movelr) {
                    movelr = 0;
                }

                var visiblelr = isOnScreen($(this));
                //alert(elementOffset);
                if (visiblelr) {
                    $(this).css({
                        left: movelr - elementOffset / 100 + "%",
                    });
                }

                //console.log(movelr);
                //console.log(scroll / 100 + "%");
            });
            $(".move-rl").each(function (index) {
                var elementOffset = $(this).offset().top - $(window).scrollTop();
                var moverl = parseInt($(this).attr("data-move-start"));
                if (!moverl) {
                    moverl = 0;
                }
                var visiblerl = isOnScreen($(this));
                if (visiblerl) {
                    $(this).css({
                        left: moverl + elementOffset / 100 + "%",
                    });
                }
            });
            $(".move-down").each(function (index) {
                var elementOffset = $(this).offset().top - $(window).scrollTop();
                var movedown = parseInt($(this).attr("data-move-start"));
                if (!movedown) {
                    movedown = 0;
                }
                var visibledown = isOnScreen($(this));
                if (visibledown) {
                    $(this).css({
                        top: movedown - elementOffset / 70 + "%",
                    });
                }
            });
            $(".move-up").each(function (index) {
                var elementOffset = $(this).offset().top - $(window).scrollTop();
                var moveup = parseInt($(this).attr("data-move-start"));
                if (!moveup) {
                    moveup = 0;
                }
                var visibleup = isOnScreen($(this));
                if (visibleup) {
                    $(this).css({
                        //bottom: moveup + scroll / 70 + "%",
                        top: moveup + elementOffset / 70 + "%",
                    });
                }
            });

        });
    }

    $(".ml").each(function (index) {

        var parts = $.map($(this).text().split(""), function (v) {
            if (v.indexOf(' ') >= 0) {
                v = "&nbsp;";
            }
            return "<span class='letter'>" + v + "</span>";
        }).join("")
        $(this).html(parts);


        // Wrap every letter in a span
        //var textWrapper = document.querySelector('.ml1');
        //textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        anime.timeline({
            loop: true
        })
            .add({
                targets: '.ml1 .letter',
                scale: [4, 1],
                opacity: [0, 1],
                translateZ: 0,
                easing: "easeOutExpo",
                duration: 950,
                delay: (el, i) => 70 * i
            }).add({
            targets: '.ml1',
            opacity: 0,
            duration: 1000,
            easing: "easeOutExpo",
            delay: 1000
        });

        anime.timeline({
            loop: true
        })
            .add({
                targets: '.ml2 .letter',
                translateX: [40, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 1200,
                delay: (el, i) => 500 + 30 * i
            }).add({
            targets: '.ml2 .letter',
            translateX: [0, -30],
            opacity: [1, 0],
            easing: "easeInExpo",
            duration: 1100,
            delay: (el, i) => 100 + 30 * i
        });
    });


    const body = document.querySelector("body");
    if (body.classList.contains('flying-hearts')) {
        function createHeart() {
            const heart = document.createElement("div");
            heart.className = "fas fa-heart";
            heart.style.left = (Math.random() * 100)+"vw";
            heart.style.animationDuration = (Math.random()*3)+2+"s"
            body.appendChild(heart);
        }
        setInterval(createHeart,100);
        setInterval(function name(params) {
            var heartArr = document.querySelectorAll(".fa-heart")
            if (heartArr.length > 200) {
                heartArr[0].remove()
            }
            //console.log(heartArr);
        },100)
    }
};
