$(document).on('turbolinks:load', () ->
  init_select2();
);
$(document).ready(() ->
  init_select2();
);

init_select2 = ->
  $('select.select2, article.form select[multiple]').select2()

  # no auto sorting for select2
  $("select").on("select2:select", (evt) ->
    return unless evt.params
    element = evt.params.data.element;
    $element = $(element);

    $element.detach();
    $(this).append($element);
    $(this).trigger("change");
  )

  # when setting this class, you can type into a text box a list of values
  #
  $('.select2_array_text_input').each(() ->
    # creating the select2 element
    element = $(this).select2({
      tags: true,
      tokenSeparators: [',', ' '],
      dropdownCssClass: 'hide'
    })

    #####################################################################
    ### select elements on clicking
    #####################################################################
    $(this).on('select2:closing', (e) ->
      value = $(e.target.nextSibling).find('.select2-search input').val()
      current = element.val() || []
      current.push(value)
      element.val(current)
      $(this).trigger('change')
      $(this).trigger('select2:select')
    )

    #####################################################################
    ### hack to allow only a given number of values
    #####################################################################
    if $(this).data('max-values')
      searchElement = null
      $(this).on('select2:select', (e) ->
        if e.target.childElementCount == $(this).data('max-values')
          searchElement = $(e.target.nextSibling).find('.select2-search')
          searchElement.hide()
      )
      $(this).on('select2:unselect', (e) ->
        if searchElement
          searchElement.show()
      )
  )
